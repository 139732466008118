import React, { useState, useEffect } from 'react';
// import UploadMedia from './UploadMedia';
import MediaFeed from './MediaFeed';
//import PromptDisplay from './PromptDisplay';
import './App.css';
//import prompts from './prompts';
import SignUpForm from './SignUpForm';
import {auth, getAuth, signInWithCustomToken, onAuthStateChanged } from './firebase-config'; // Import your config
// import InstallButton from './InstallButton';
import EditProfileModal from './EditProfileModal'; // Import the modal
import SMSForm from './SMSForm';
import './index.css';
import UploadPrompts from './UploadPrompts'; // Assuming the component is in the same directory
import Camera from './Camera'; // Import the Camera component


function App() {
  const [activeTab, setActiveTab] = useState(''); // State to track active tab
  const [users, setUsers] = useState([]);
  //const [currentPromptIndex, setCurrentPromptIndex] = useState(0);
  //const vapidKey = 'BEAKYF6KNgTsSG9kU-hg02-Sx1IMVTwHp7FGIWa3lZtoDhR2054YrtX_mrVdjMSINgXMZhwFTrkbyTtqtmPc9mE';
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
  const [phoneNumber, setPhoneNumber] = useState(''); // Add phoneNumber state here
  const [userId, setUserId] = useState(''); // State to store user ID
  const [mediaFeed, setMediaFeed] = useState([]); // For managing media feed

  // Function to sign in with a custom token from the URL
  const signInWithCustomLink = (token) => {
    const auth = getAuth();
    signInWithCustomToken(auth, token)
      .then((result) => {
        console.log('User signed in:', result.user);
      })
      .catch((error) => {
        console.error('Error signing in with custom token:', error);
      });
  };

  // On component mount, check for the token in the URL
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token'); // Get the custom token from the URL
    if (token) {
      signInWithCustomLink(token); // If token exists, sign the user in
    }
  }, []); // Empty dependency array to run once on component mount


  // Listen for authentication changes and set the user
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUsers(user);
        setUserId(user.uid); // Set the user ID
        setActiveTab('mediafeed');
      } else {
        setUsers(null);
        setActiveTab('signup');
      }
    });

    return () => unsubscribe();
  }, []);  // Listen for authentication changes and set the user

  const addUser = (user, name) => {
    user.displayName = name;
    setUsers([...users, user]);
  };

  const addMedia = (newMedia) => {
    setMediaFeed([...mediaFeed, newMedia]); // Add new media to the feed
  };

  // Function to handle tab click
  const handleTabClick = (tab) => {
    setActiveTab(tab);
    // Scroll to the top of the page smoothly
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Adds smooth scrolling
    });
  };

  const handleProfileClick = () => {
    setIsModalOpen(true); // Open the modal when the profile is clicked
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  // Function to handle phone number change
  const handlePhoneNumberChange = (number) => {
    setPhoneNumber(number); // Update the phone number state
  };

  const handleCloseCamera = () => {
    setActiveTab('mediafeed'); // Close camera by switching back to media feed
  };

  return (
    <div className="App">
      <header className="App-header">
        <button
          className={`tab-button ${activeTab === 'mediafeed' ? 'active' : ''}`}
          onClick={() => window.location.reload()} // Refresh the page when clicked
        >
        {/* <img
          src="\snappo.png"
        /> */}
        <span><h1>snappo</h1></span>
        </button>

        <div className="header-right">
          {/* Show Profile Picture if User is Logged In */}
          {users && users.photoURL ? (
            <img
              src={users.photoURL}
              alt={users.displayName || 'User'}
              className="profile-picture"
              onClick={handleProfileClick} // Make the profile picture clickable
              style={{ cursor: 'pointer' }}
            />
          ) : (
            // Show a placeholder if no profile picture is available
            <span
              className="material-icons large-icon"
              onClick={handleProfileClick} // Make placeholder icon clickable
              style={{ cursor: 'pointer' }}
            >
              account_circle
            </span>
          )}
        </div>
      </header>
      <div className="tabs">
        <button className={`tab-button ${activeTab === 'mediafeed' ? 'active' : ''}`} onClick={() => handleTabClick('mediafeed')}>
          <span className={`${activeTab === 'mediafeed' ? 'material-icons' : 'material-icons-outlined'} large-icon`}>
            home
          </span>
        </button>
        <button className={`tab-button ${activeTab === 'camera' ? 'active' : ''}`} onClick={() => handleTabClick('camera')}>
          <span className={`${activeTab === 'camera' ? 'material-icons' : 'material-icons-outlined'} large-icon`}>
            camera
          </span>
        </button>
        <button className={`tab-button ${activeTab === 'signup' ? 'active' : ''}`} onClick={() => handleTabClick('signup')}>
          <span className={`${activeTab === 'signup' ? 'material-icons' : 'material-icons-outlined'} large-icon`}>
            login
          </span>
        </button>
        {(userId === 'Nf2mxjCt6aTsgYNhVdG7eMIdgZO2' || userId === 'YOBAtkmQrdaJohjsOn0hOqeQFZ62') && (
          <button className={`tab-button ${activeTab === 'SMS' ? 'active' : ''}`} onClick={() => handleTabClick('SMS')}>
            <span className={`${activeTab === 'SMS' ? 'material-icons' : 'material-icons-outlined'} large-icon`}>
              sms
            </span>
          </button>
        )}
        {(userId === 'Nf2mxjCt6aTsgYNhVdG7eMIdgZO2' || userId === 'YOBAtkmQrdaJohjsOn0hOqeQFZ62') && (
          <button className={`tab-button ${activeTab === 'uploadprompts' ? 'active' : ''}`} 
          onClick={() => handleTabClick('uploadprompts')}>
            <span className={`${activeTab === 'uploadprompts' ? 'material-icons' : 'material-icons-outlined'} large-icon`}>
              event_note
            </span>
          </button>
        )}
      </div>

      <div className="tab-content">
        {activeTab === 'signup' && <SignUpForm onSignUpSuccess={addUser}/>}
        {activeTab === 'camera' && (
          <Camera 
          addMedia={addMedia}
          onClose={handleCloseCamera}
          />
        )}
        {activeTab === 'mediafeed' && <MediaFeed/>}
        {(userId === 'Nf2mxjCt6aTsgYNhVdG7eMIdgZO2' || userId === 'YOBAtkmQrdaJohjsOn0hOqeQFZ62') && activeTab === 'SMS' && (
          <SMSForm phoneNumber={phoneNumber} onPhoneNumberChange={handlePhoneNumberChange} />
        )}
        {/* Render UploadPrompts when the active tab is 'uploadprompts' */}
        {(userId === 'Nf2mxjCt6aTsgYNhVdG7eMIdgZO2' || userId === 'YOBAtkmQrdaJohjsOn0hOqeQFZ62') && activeTab === 'uploadprompts' && (
          <UploadPrompts />
        )}
      </div>
      {/* Render the Edit Profile Modal if isModalOpen is true */}
      {isModalOpen && (
        <EditProfileModal user={users} onClose={handleCloseModal} />
      )}
    </div>
  );
}

export default App;
