// SMSForm.js
import React, { useState } from 'react';
import axios from 'axios';
import './SMSForm.css';

function SMSForm({ phoneNumber, onPhoneNumberChange }) {
  const [message, setMessage] = useState('welcome to snappo!');

  const handleSendSMS = async () => {
    try {
      // Call the Firebase Cloud Function
      const response = await axios.post('https://us-central1-vip-feed-d30d0.cloudfunctions.net/sendSMS', {
        to: phoneNumber,
        body: message,
      });

      if (response.status === 200) {
        alert('SMS sent successfully!');
      } else {
        alert('Failed to send SMS');
      }
    } catch (error) {
      console.error('Error sending SMS:', error);
    }
  };

  return (
    <div className="sms-form-container">
      <h2>Send Message</h2>
        <input
          type="text"
          placeholder="Enter phone number"
          value={phoneNumber}
          onChange={(e) => onPhoneNumberChange(e.target.value)}
        />
        <div className="input-container">
        <textarea
          type="text"
          placeholder="Enter message..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          rows={2} /* Adjust the number of visible rows */
          style={{ width: '100%' }} /* Make it full width if needed */
        />
        <button onClick={handleSendSMS}>
          <span className="material-icons small-icon">send</span>
        </button>
      </div>
    </div>
  );
}

export default SMSForm;
