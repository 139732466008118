import React, { useState, useEffect, useRef } from 'react';
import { storage, firestore, ref, uploadBytes, getDownloadURL, collection, addDoc, auth, serverTimestamp, onAuthStateChanged } from './firebase-config';
import './UploadMediaModal.css'; // Your custom styles
import './FABUploadMedia.css'; // Your custom styles

function UploadMediaModal({ addMedia, currentPrompt, onClose }) {
  const [file, setFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [caption, setCaption] = useState(""); // Caption/comment
  const [userId, setUserId] = useState(null); // User ID from Firebase Auth
  const [displayName, setDisplayName] = useState(""); // User's display name
  const fileInputRef = useRef(null); // Reference to the file input element
  const [loading, setLoading] = useState(false); // To prevent multiple submissions

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
        setDisplayName(user.displayName);
      } else {
        setUserId(null);
        setDisplayName("");
      }
    });

    return () => unsubscribe();
  }, []);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      const preview = URL.createObjectURL(selectedFile);
      setPreviewUrl(preview);
    }
  };

  const handleCaptionChange = (e) => {
    setCaption(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (file && userId) {
      setLoading(true);

      try {
        const storageRef = ref(storage, `media/${file.name}`);
        await uploadBytes(storageRef, file);
        const fileUrl = await getDownloadURL(storageRef);

        const newMedia = {
          prompt: currentPrompt,
          displayname: displayName,
          caption: caption,
          src: fileUrl,
          name: file.name,
          timestamp: serverTimestamp(),
          userId: userId,
          type: 'media',
        };

        const docRef = await addDoc(collection(firestore, 'media'), newMedia);
        console.log('Document written with ID:', docRef.id);
        addMedia(newMedia);

        setFile(null);
        setPreviewUrl(null);
        setCaption("");
        fileInputRef.current.value = "";
        onClose(); // Close the modal after successful upload
      } catch (error) {
        console.error('Error uploading file:', error);
      } finally {
        setLoading(false);
      }
    } else {
      console.error('No file selected or user not logged in.');
    }
  };

  return (
    <div className="uploadMedia-modal-overlay">
      <div className="uploadMedia-modal-content">
        <button className="media-modal-close" onClick={onClose}>
          <span className="material-icons">close</span>
        </button>
        
          <form onSubmit={handleSubmit} className="upload-media-form">
            <label className="custom-file-upload">
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                ref={fileInputRef}
                required
              />
              Choose Image
            </label>

          {previewUrl && (
            <div className="image-preview-container">
              <img src={previewUrl} alt="Preview" className="image-preview" />
            </div>
          )}

          <textarea
            className="caption-input"
            placeholder="Write a caption..."
            value={caption}
            onChange={handleCaptionChange}
          />

          <button type="submit" className="media-submit-btn" disabled={loading}>
            {loading ? 'Posting...' : 'Post'}
          </button>
        </form>
      </div>
    </div>
  );
}

export default UploadMediaModal;
