import React, { useState, useRef, useEffect } from 'react';
import { storage, firestore, ref, uploadBytes, getDownloadURL, collection, addDoc, auth, serverTimestamp, onAuthStateChanged } from './firebase-config';
import './Camera.css';

function Camera({ addMedia, currentPrompt, onClose }) {
  const [file, setFile] = useState(null);
  const [comment, setComment] = useState("");
  const [previewUrl, setPreviewUrl] = useState(null);
  const [userId, setUserId] = useState(null);
  const [displayName, setDisplayName] = useState("");
  const [isFlashOn, setIsFlashOn] = useState(false);
  const [isFrontCamera, setIsFrontCamera] = useState(false);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const streamRef = useRef(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
        setDisplayName(user.displayName);
      } else {
        setUserId(null);
        setDisplayName("");
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const startCamera = () => {
      if (!videoRef.current) return;
  
      const facingMode = isFrontCamera ? "user" : "environment";
  
      navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: facingMode,
          width: { ideal: 1280 },
          height: { ideal: 720 },
        },
      })
        .then((stream) => {
          streamRef.current = stream;
          videoRef.current.srcObject = stream;
          videoRef.current.onloadedmetadata = () => {
            videoRef.current.play().catch((error) => {
              console.warn("Video playback failed:", error);
            });
          };
        })
        .catch((err) => console.error("Error accessing camera:", err));
    };

    if (!previewUrl) {
      startCamera(); // Start the camera when the component mounts or after clearing preview
    }
    return () => {
      stopCamera(); // Stop the camera when the component unmounts or modal is closed
    };
  }, [previewUrl, isFrontCamera]);

  
  const stopCamera = () => {
    if (streamRef.current) {
      const tracks = streamRef.current.getTracks();
      tracks.forEach((track) => {
        if (track.readyState === 'live') {
          track.stop();
        }
      });
      streamRef.current = null;
    }

    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.srcObject = null;
    }
  };

  const captureImage = () => {
    if (!videoRef.current) return;

    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;

    if (isFrontCamera) {
      context.translate(canvas.width, 0); // Move the canvas context to the right
      context.scale(-1, 1); // Flip the context horizontally
    }

    context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
    const imageDataUrl = canvas.toDataURL('image/png');
    setFile(imageDataUrl);
    setPreviewUrl(imageDataUrl);
    stopCamera(); // Stop the camera after capturing the image
  };

  const handleClearImage = () => {
    setFile(null);
    setPreviewUrl(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (file && userId) {
      try {
        const storageRef = ref(storage, `media/${Date.now()}.png`);
        const fileBlob = await (await fetch(file)).blob();

        await uploadBytes(storageRef, fileBlob);
        const fileUrl = await getDownloadURL(storageRef);

        const newMedia = {
          displayname: displayName,
          comment: comment,
          src: fileUrl,
          timestamp: serverTimestamp(),
          userId: userId,
          type: 'media',
        };

        await addDoc(collection(firestore, 'media'), newMedia);
        addMedia(newMedia);
        setFile(null);
        setPreviewUrl(null);
        setComment("");
        handleCloseCamera();
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    } else {
      console.error('No image captured or user not logged in.');
    }
  };

  const handleCloseCamera = () => {
    stopCamera();
    onClose();
  };

  const handleFlipCamera = () => {
    setIsFrontCamera((prev) => !prev);
  };

  const handleToggleFlash = () => {
    setIsFlashOn((prev) => !prev);
    // Note: Flash control is generally not possible through web browsers.
  };

  return (
    <div className="camera-modal-overlay">
      <div className="camera-modal-content">
          <button onClick={handleCloseCamera} className="camera-close-btn">
            <span className="material-icons camera-close-icon">close</span>
          </button>

        <div className="camera-preview-section">
          {previewUrl ? (
            <div className="camera-image-preview-container">
              <img src={previewUrl} alt="Captured" className="camera-image-preview" />
              <button onClick={handleClearImage} className="camera-clear-btn">
                <span className="material-icons retake-icon">camera</span>
              </button>
              <button onClick={handleSubmit} className="camera-submit-btn">
                <span className="material-icons submit-icon">send</span>
              </button>
            </div>
          ) : (
            <div className="video-wrapper">
              <video 
                ref={videoRef} 
                className={`camera-video-stream ${isFrontCamera ? 'mirrored' : ''}`}
                playsInline 
                autoPlay>
              </video>
              <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
              
              <button onClick={captureImage} className="camera-capture-btn">
                <div className="camera-capture-btn-inner"></div>
              </button>
            </div>
          )}
        </div>

        {!previewUrl && (
          <>
            <button onClick={handleFlipCamera} className="camera-flip-btn">
              <span className="material-icons camera-icon">cameraswitch</span>
            </button>
            <button onClick={handleToggleFlash} className="camera-flash-btn">
              <span className="material-icons camera-icon">
                {isFlashOn ? "flash_on" : "flash_off"}
              </span>            
            </button>
          </>
        )}

      </div>
    </div>
  );
}

export default Camera;
