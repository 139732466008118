import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithCustomToken, sendPasswordResetEmail, onAuthStateChanged, updateProfile } from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getFirestore, collection, addDoc, serverTimestamp, getDocs, deleteDoc, getDoc, query, where, doc, setDoc, updateDoc, arrayUnion, arrayRemove } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAhou6WpxXSlUxmfqzpZxeYyYPXevJXDGs",
  authDomain: "vip-feed-d30d0.firebaseapp.com",
  databaseURL: "https://vip-feed-d30d0-default-rtdb.firebaseio.com",
  projectId: "vip-feed-d30d0",
  storageBucket: "vip-feed-d30d0.appspot.com",
  messagingSenderId: "603002084929",
  appId: "1:603002084929:web:6ad9f3e833ac311b4f70c0",
  measurementId: "G-H627EZ30GH"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app); // Initialize Firebase Auth
const storage = getStorage(app);
const firestore = getFirestore(app);
const messaging = getMessaging(app);


export { 
  firebaseConfig, 
  app, 
  messaging, 
  getToken, 
  onMessage, 
  getDoc, 
  getDocs,
  doc,
  setDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  query, 
  where,
  auth,
  getAuth,
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  signInWithCustomToken,
  sendPasswordResetEmail,
  onAuthStateChanged, 
  updateProfile,
  storage, 
  firestore, 
  ref, 
  uploadBytes, 
  getDownloadURL, 
  collection, 
  addDoc, 
  deleteDoc,
  serverTimestamp };