import React, { useState, useEffect } from 'react';
import { auth, onAuthStateChanged, firestore, collection, getDocs, doc, deleteDoc, updateDoc, arrayUnion, arrayRemove, getDoc } from './firebase-config';
import './MediaFeed.css';
import './App.css';
import FABUploadMedia from './FABUploadMedia';
import './FABUploadMedia.css';

function MediaFeed() {
  const [userId, setUserId] = useState(null);
  const [media, setMedia] = useState([]);
  const [likes, setLikes] = useState({});  
  const [userSaves, setUserSaves] = useState({});
  const [userLikes, setUserLikes] = useState({});
  const [userProfiles, setUserProfiles] = useState({}); // State to store user profile pictures
  const [newComment, setNewComment] = useState({}); // To track comments for each media
  const [showComments, setShowComments] = useState({}); // Track visibility of comments for each media item
  const [mediaToDelete, setMediaToDelete] = useState(null); // Track which media item to delete
  const [prompts, setPrompts] = useState([]); // State for storing prompts
  const [combinedFeed, setCombinedFeed] = useState([]); // Combined feed of media and prompts
  
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
      } else {
        setUserId(null);
      }
    });
  
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchMedia = async () => {
      try {
        const mediaQuery = collection(firestore, 'media');
        const querySnapshot = await getDocs(mediaQuery);
        const mediaData = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          const timestamp = data.timestamp ? data.timestamp.toDate() : null;
          return {
            id: doc.id,
            ...data,
            timestamp: timestamp, // Convert Firestore Timestamp to JavaScript Date
            comments: data.comments || [],
            userId: data.userId, // Ensure userId is included in each media item
          };
        });

        const sortedMedia = mediaData.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
        setMedia(sortedMedia);

        // Fetch likes
        const likesData = {};
        const userLikesData = {};
        for (const doc of querySnapshot.docs) {
          const data = doc.data();
          const mediaId = doc.id;
          likesData[mediaId] = data.likes ? data.likes.length : 0;
          if (data.likes && data.likes.includes(userId)) {
            userLikesData[mediaId] = true;
          }
        }
        setLikes(likesData);
        setUserLikes(userLikesData);
      } catch (error) {
        console.error('Error fetching media:', error);
      }
    };

    if (userId) {
      fetchMedia();
    }
  }, [userId]);


  useEffect(() => {
    const fetchPrompts = async () => {
      try {
        const promptsQuery = collection(firestore, 'prompts'); // Assuming 'prompts' collection
        const querySnapshot = await getDocs(promptsQuery);
        const promptsData = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          const timestamp = data.timestamp ? data.timestamp.toDate() : null;
          return {
            id: doc.id,
            type: 'prompt', // Indicate it's a prompt
            ...data,
            timestamp: timestamp,
            delivered: data.delivered || 'No',
          };
        });
        setPrompts(promptsData);
      } catch (error) {
        console.error('Error fetching prompts:', error);
      }
    };
  
    fetchPrompts();
  }, []);

  useEffect(() => {
    const combined = [...media, ...prompts]; // Combine media and prompts
    const sortedCombined = combined.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)); // Sort by date
    setCombinedFeed(sortedCombined);
  }, [media, prompts]); // Re-run whenever media or prompts change  

  useEffect(() => {
    const fetchUserProfiles = async () => {
      try {
        const profiles = {};
        const uniqueUserIds = [...new Set(media.map(item => item.userId))]; // Get unique userIds

          // Fetch profiles only for unique userIds
          for (const userId of uniqueUserIds) {
            const profileDoc = await getDoc(doc(firestore, 'users', userId));
            if (profileDoc.exists()) {
              profiles[userId] = profileDoc.data().photoURL || ''; // Store the profile picture URL
            }
          }
        
        setUserProfiles(profiles); // Store fetched profile pictures in state
      } catch (error) {
        console.error('Error fetching user profiles:', error);
      }
    };

    if (media.length > 0) {
      fetchUserProfiles();
    }
  }, [media]);

  const handleLike = async (itemId) => {
    try {
      const mediaDoc = doc(firestore, 'media', itemId);
      if (userLikes[itemId]) {
        await updateDoc(mediaDoc, {
          likes: arrayRemove(userId),
        });
      } else {
        await updateDoc(mediaDoc, {
          likes: arrayUnion(userId),
        });
      }

      const updatedDoc = await getDoc(mediaDoc);
      const updatedData = updatedDoc.data();
      const updatedLikesCount = updatedData.likes ? updatedData.likes.length : 0;

      setLikes((prevLikes) => ({
        ...prevLikes,
        [itemId]: updatedLikesCount,
      }));
      setUserLikes((prevUserLikes) => ({
        ...prevUserLikes,
        [itemId]: !userLikes[itemId],
      }));
    } catch (error) {
      console.error('Error updating likes:', error);
    }
  };

  const handleCommentChange = (mediaId, comment) => {
    setNewComment((prev) => ({
      ...prev,
      [mediaId]: comment, // Store new comment per media item
    }));
  };

  const handleCommentSubmit = async (mediaId) => {
    if (newComment[mediaId]?.trim()) {
      try {
        const mediaDoc = doc(firestore, 'media', mediaId);
        await updateDoc(mediaDoc, {
          comments: arrayUnion({
            user: auth.currentUser.displayName, // Add the current user's display name
            text: newComment[mediaId],
          }),
        });

        const updatedDoc = await getDoc(mediaDoc);
        const updatedData = updatedDoc.data();

        // Update local state
        setMedia((prevMedia) =>
          prevMedia.map((item) =>
            item.id === mediaId
              ? { ...item, comments: updatedData.comments }
              : item
          )
        );

        // Clear the comment input
        setNewComment((prev) => ({
          ...prev,
          [mediaId]: '',
        }));
      } catch (error) {
        console.error('Error adding comment:', error);
      }
    }
  };

  const toggleComments = (mediaId) => {
    setShowComments((prev) => ({
      ...prev,
      [mediaId]: !prev[mediaId], // Toggle the visibility of comments for the specific media
    }));
  };

  // Function to open the confirmation popup
  const confirmDelete = (mediaId) => {
    setMediaToDelete(mediaId); // Set the media item that is to be deleted
  };

  // Function to handle deletion
  const handleDelete = async () => {
    try {
      await deleteDoc(doc(firestore, 'media', mediaToDelete)); // Delete the document from Firestore
      setMedia((prevMedia) => prevMedia.filter((item) => item.id !== mediaToDelete)); // Update the local state to remove the deleted item
      setMediaToDelete(null); // Reset the selected media item after deletion
    } catch (error) {
      console.error('Error deleting media:', error);
    }
  };

  // Function to cancel deletion
  const handleCancel = () => {
    setMediaToDelete(null); // Close the confirmation form
  };

  const handleSave = (itemId) => {
    setUserSaves((prevUserSaves) => ({
      ...prevUserSaves,
      [itemId]: !prevUserSaves[itemId], // Toggle saved state
    }));
  };

  return (
    <div className="media-feed">
      <FABUploadMedia />

      <div className="media-list">
        {combinedFeed.map((item, itemIndex) => (
          <div className="media-item" key={itemIndex}>
            {item.type === 'media' ? (
              <>
              <div className="media-content-wrapper">
                <div className="media-header">
                {userProfiles[item.userId] ? (
                  <img
                    src={userProfiles[item.userId]}
                    alt={item.displayname}
                    className="media-profile-picture"
                  />
                ) : (
                  <span className="material-icons media-profile-picture">account_circle</span>
                )}
                  <span className="username">{item.displayname}</span>
                  
                  {item.userId === userId && (
                    <button onClick={() => confirmDelete(item.id)} className="delete-button">
                      <span className="material-icons">delete_outline</span>
                    </button>
                  )}
            </div>

            <img src={item.src} alt={item.name} className="media-content"/>

            {item.caption && (
              <div className="media-caption">
                <p>{item.caption}</p>
              </div>
            )}
          </div>

            <div className="media-reactions">
              <div className="reaction-buttons">
                <button onClick={() => handleLike(item.id)} className={`like-button ${userLikes[item.id] ? 'liked' : ''}`}>
                  <span className="material-icons">
                    {userLikes[item.id] ? 'favorite' : 'favorite_outline'}
                  </span>
                  <span className="like-count">{likes[item.id] || 0}</span> {/* Display like count */}
                </button>

                <button onClick={() => toggleComments(item.id)} className="comment-button">
                  <span className="material-icons">
                    {showComments[item.id] ? 'chat' : 'chat_bubble_outline'}
                  </span>
                  <span className="comment-count">{item.comments.length || 0}</span> {/* Display comment count */}
                </button>
              </div>

              <button onClick={() => handleSave(item.id)} className="save-collection-button">
                <span className="material-icons-outlined">
                  {userSaves[item.id] ? 'bookmark' : 'bookmark_border'}
                </span>
              </button>
            </div>

            {/* Comments Section */}
            {showComments[item.id] && (
              <>
                {item.comments.length > 0 && (
                  <div className="comments-section">
                    {/* <h4>Comments:</h4> */}
                    {item.comments.map((comment, commentIndex) => (
                      <div key={commentIndex} className="comment-item">
                        <span className="comment-user">{comment.user}:</span>
                        <span className="comment-text">{comment.text}</span>
                      </div>
                    ))}
                  </div>
                )}

                {/* Comment Input Section */}
                <div className="comment-input-section">
                  <input
                    type="text"
                    placeholder="Add a comment..."
                    value={newComment[item.id] || ''} // Controlled input for each media item
                    onChange={(e) => handleCommentChange(item.id, e.target.value)}
                    className="comment-input"
                  />
                  <button
                    onClick={() => handleCommentSubmit(item.id)}
                    className="material-icons submit-button">
                    send
                  </button>
                </div>
              </>
            )}
          </>
        ) : (
          // Only display the prompt if it has been delivered
          item.delivered === 'Yes' && (
            <div className="chat-container">
              <div className="chat-bubble">
                <span className="prompt-description">
                  {item.promptDescription}
                </span>
                <span className="prompt-timestamp">
                  Delivered at {item.timestamp ? item.timestamp.toLocaleString() : 'No timestamp available'}
                </span>
              </div>
            </div>
          )
        )}
      </div>
    ))}
  </div>

  {/* Confirmation Popup */}
  {mediaToDelete && (
    <div className="delete-confirmation">
      <div className="confirmation-content">
        <p>Are you sure you want to delete this post?</p>
        <button onClick={handleDelete} className="confirm-delete">
          Yes, Delete
        </button>
        <button onClick={handleCancel} className="cancel-delete">
          Cancel
        </button>
      </div>
    </div>
  )}
</div>
);
}
export default MediaFeed;
