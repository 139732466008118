import React, { useState, useEffect } from 'react';
import { firestore, collection, addDoc, getDocs } from './firebase-config';
import './UploadPrompts.css'; // You can add styling as needed
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function UploadPrompts() {
  const [promptDescription, setPromptDescription] = useState('');
  const [scheduledDate, setScheduledDate] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [prompts, setPrompts] = useState([]);

  // Function to fetch existing prompts from Firestore
  const fetchPrompts = async () => {
    try {
      const querySnapshot = await getDocs(collection(firestore, 'prompts'));
      const promptList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPrompts(promptList);
    } catch (error) {
      console.error('Error fetching prompts:', error);
    }
  };

  useEffect(() => {
    fetchPrompts();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate that all fields are filled in
    if (!promptDescription || !scheduledDate) {
      setError('Please fill in all fields.');
      return;
    }

    // Reset error and success messages
    setError('');
    setSuccessMessage('');

    try {
      // Add the new prompt to Firestore
      await addDoc(collection(firestore, 'prompts'), {
        promptDescription: promptDescription,
        timestamp: new Date(scheduledDate),
        delivered: 'No',
      });

      // Success feedback
      setSuccessMessage('Prompt scheduled successfully!');
      
      // Reset form fields
      setPromptDescription('');
      setScheduledDate('');

      // Fetch the updated list of prompts
      fetchPrompts();
    } catch (error) {
      console.error('Error adding prompt:', error);
      setError('Error adding prompt. Please try again.');
    }
  };

  return (
    <div className="upload-prompts-page">
      <form onSubmit={handleSubmit} className="upload-prompt-form">
        <h1>New Prompt</h1>
        <div className="form-group">
          <textarea
            id="promptDescription"
            value={promptDescription}
            onChange={(e) => setPromptDescription(e.target.value)}
            placeholder="Enter prompt description"
          ></textarea>
        </div>

        <div className="form-group">
          <DatePicker
            selected={scheduledDate}
            onChange={(date) => setScheduledDate(date)}
            showTimeSelect
            dateFormat="Pp"
            placeholderText="Select Date"
          />
        </div>

        {error && <p className="error-message">{error}</p>}
        {successMessage && <p className="success-message">{successMessage}</p>}

        <button type="submit" className="prompt-submit-button">Submit</button>
      </form>

      {/* Display the list of prompts */}
      <h2>Scheduled Prompts</h2>
      <table className="prompts-table">
        <thead>
          <tr>
            <th>Prompt</th>
            <th>Scheduled Date</th>
            <th>Delivered</th>
          </tr>
        </thead>
        <tbody>
          {prompts.map((prompt) => (
            <tr key={prompt.id}>
              <td>{prompt.promptDescription}</td>
              <td>{new Date(prompt.timestamp.seconds * 1000).toLocaleString()}</td>
              <td>{prompt.delivered}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default UploadPrompts;
