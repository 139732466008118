import React, { useState } from 'react';
import { auth, updateProfile, storage, ref, uploadBytes, getDownloadURL, firestore, doc, setDoc } from './firebase-config'; // Import Firestore
import './EditProfileModal.css'; // Import the consistent styles

function EditProfileModal({ user, onClose }) {
  const [displayName, setDisplayName] = useState(user.displayName || '');
  const [profilePicture, setProfilePicture] = useState(null);
  const [preview, setPreview] = useState(user.photoURL || '');
  const [isUploading, setIsUploading] = useState(false); // To handle the loading state

  // Handle profile picture change
  const handleProfilePictureChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfilePicture(file);
      setPreview(URL.createObjectURL(file));
    }
  };

  // Handle form submission to update profile
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsUploading(true); // Start loading

    try {
      let profilePictureUrl = user.photoURL;

      // If a new profile picture is selected, upload it to Firebase Storage
      if (profilePicture) {
        const storageRef = ref(storage, `profiles/${auth.currentUser.uid}/${profilePicture.name}`);
        await uploadBytes(storageRef, profilePicture); // Upload the image
        profilePictureUrl = await getDownloadURL(storageRef); // Get the uploaded image URL
      }

      // Update the user's profile in Firebase Authentication
      await updateProfile(auth.currentUser, {
        displayName,
        photoURL: profilePictureUrl,
      });

      // Save the profile data to Firestore
      const userDocRef = doc(firestore, 'users', user.uid); // Reference to the user document
      await setDoc(userDocRef, {
        uid: auth.currentUser.uid, // Add the uid to the document
        displayName: displayName,
        photoURL: profilePictureUrl,
      }, { merge: true }); // Merge to prevent overwriting other fields

      alert('Profile updated successfully!');
      setIsUploading(false); // Stop loading
      onClose(); // Close the modal
    } catch (error) {
      console.error('Error updating profile:', error);
      setIsUploading(false); // Stop loading in case of error
    }
  };

  return (
    <div className="edit-modal-overlay">
      <div className="edit-modal-content">
        {/* Close Modal Button */}
        <button className="modal-close" onClick={onClose} disabled={isUploading}>
          <span className="material-icons">close</span>
        </button>

        {/* User info at the top */}
        <div className="profile-header">
          <img src={preview} alt="Profile Icon" className="profile-icon" />
          <span className="modal-username">{displayName}</span>
        </div>

        <form onSubmit={handleSubmit} className="edit-profile-form">
          <div className="form-group">
            {/* <label htmlFor="displayName">Display Name</label> */}
            <input
              type="text"
              id="displayName"
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
              disabled={isUploading} // Disable input when uploading
            />
          </div>

          <div className="form-group">
            {/* <label htmlFor="profilePicture">Profile Picture</label> */}
            <label className="custom-file-upload">
              <input
                type="file"
                id="profilePicture"
                accept="image/*"
                onChange={handleProfilePictureChange}
                disabled={isUploading} // Disable input when uploading
              />
              Choose Image
            </label>
            {preview && <img src={preview} alt="Profile Preview" className="profile-preview" />}
          </div>

          <button type="submit" disabled={isUploading} className="save-btn">
            {isUploading ? 'Saving...' : 'Save'}
          </button>
        </form>
      </div>
    </div>
  );
}

export default EditProfileModal;
